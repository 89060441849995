import { MissingImageIcon } from '@/images/product';
import { useIsMobile } from '@/lib';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';

import { Supplier } from '../../../graphql-types';

type SupplierCardListProps = {
  className?: string;
  suppliers: Supplier[];
};

const SupplierCardList: FC<SupplierCardListProps> = ({
  className,
  suppliers,
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={clsx(
        className,
        'grid justify-between gap-2 lg:gap-10 max-w-full'
      )}
      style={{
        gridTemplateColumns: isMobile
          ? 'repeat(auto-fill, minmax(15rem, 1fr))'
          : 'repeat(auto-fill, 15rem)',
      }}
    >
      {suppliers
        .map((supplier, idx) => ({ supplier, idx }))
        .map(({ supplier, idx }) => (
          <Link
            key={idx}
            to={supplier.path}
            className={clsx(
              'flex gap-4 flex-col bg-white p-3 h-full border border-yellow'
            )}
          >
            {supplier.image ? (
              <GatsbyImage
                className="w-32 h-32 lg:w-44 lg:h-44 self-center"
                alt={supplier.name}
                image={getImage(supplier.image as any)}
                objectFit="contain"
              />
            ) : (
              <MissingImageIcon className="w-32 h-32 lg:w-44 lg:h-44 text-gray-250 self-center" />
            )}
            <div className="flex-1 flex flex-col min-w-0">
              <div className="text-gray-500 font-semibold">{supplier.name}</div>
              <div className="text-xs opacity-50 truncate">
                {supplier.areaServed ?? 'Romania'}
              </div>
              <div className="flex-1" />
              <div className="text-base mt-4 flex flex-wrap gap-2">
                {supplier.tags.map((tag) => (
                  <div
                    key={tag}
                    className="bg-primary-100 px-2 py-1 text-white text-xs rounded-full text-sm"
                  >
                    {tag}
                  </div>
                ))}
              </div>
              {supplier.category && (
                <div className="bg-yellow text-center mt-4 -mx-3 -mb-3 px-4 py-2">
                  {supplier.category}
                </div>
              )}
            </div>
          </Link>
        ))}
    </div>
  );
};

export default SupplierCardList;
