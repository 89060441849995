import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC } from 'react';

type SocialProofBarProps = {
  className?: string;
  withLinks: boolean;
};

const LINKS = {
  profitRo:
    'https://www.profit.ro/profit-news-tv/profit-news-tv-urmeaza-o-noua-runda-de-finantare-pentru-startup-ul-optioffer-co-fondator-nu-am-crezut-ca-se-poate-face-asa-ceva-in-oradea-e-chiar-locul-in-care-putem-face-o-diferenta-20562339',
  theRecursive:
    'https://therecursive.com/make-it-in-oradea-makes-first-investment-pre-seed-round-b2b-platform-optioffer/',
  roInsider:
    'https://www.romania-insider.com/makeit-oradea-funding-optioffer-dec-2021',
  startUp:
    'https://start-up.ro/optioffer-startup-ul-care-optimizeaza-crearea-de-oferte-comerciale/',
  zf: 'https://www.zf.ro/business-hi-tech/start-up-ul-optioffer-platforma-b2b-care-conecteaza-furnizorii-si-20417533',
};

const SocialProofBar: FC<SocialProofBarProps> = ({ className, withLinks }) => {
  const { profitRo, theRecursive, roInsider, startUp, zf } =
    useStaticQuery(graphql`
      fragment BlurredMaxQuality on File {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }

      query {
        profitRo: file(relativePath: { eq: "fso/logo-profit-ro.png" }) {
          ...BlurredMaxQuality
        }
        theRecursive: file(relativePath: { eq: "fso/logo-recursive.png" }) {
          ...BlurredMaxQuality
        }
        roInsider: file(relativePath: { eq: "fso/logo-romania-insider.png" }) {
          ...BlurredMaxQuality
        }
        startUp: file(relativePath: { eq: "fso/logo-start-up.png" }) {
          ...BlurredMaxQuality
        }
        zf: file(relativePath: { eq: "fso/logo-zf.png" }) {
          ...BlurredMaxQuality
        }
      }
    `);

  const Item = withLinks ? 'a' : 'div';
  const itemClassName = 'w-24 flex justify-center';

  return (
    <section className={clsx(className, 'py-6')}>
      <div className="container m-auto h-full">
        <div className="flex flex-wrap items-center justify-around h-full gap-4 px-4 lg:px-12">
          <Item
            className={itemClassName}
            href={withLinks ? LINKS.zf : undefined}
            target={withLinks ? '_blank' : undefined}
          >
            <GatsbyImage
              image={getImage(zf)}
              alt="Ziarul Financiar"
              className="h-6"
              objectFit="contain"
            />
          </Item>
          <Item
            className={itemClassName}
            href={withLinks ? LINKS.roInsider : undefined}
            target={withLinks ? '_blank' : undefined}
          >
            <GatsbyImage
              image={getImage(roInsider)}
              alt="Romania Insider"
              className="h-6"
              objectFit="contain"
            />
          </Item>
          <Item
            className={itemClassName}
            href={withLinks ? LINKS.theRecursive : undefined}
            target={withLinks ? '_blank' : undefined}
          >
            <GatsbyImage
              image={getImage(theRecursive)}
              alt="The Recursive"
              className="h-6"
              objectFit="contain"
            />
          </Item>
          <Item
            className={itemClassName}
            href={withLinks ? LINKS.profitRo : undefined}
            target={withLinks ? '_blank' : undefined}
          >
            <GatsbyImage
              image={getImage(profitRo)}
              alt="Profit.ro"
              className="h-6"
              objectFit="contain"
            />
          </Item>
          <Item
            className={itemClassName}
            href={withLinks ? LINKS.startUp : undefined}
            target={withLinks ? '_blank' : undefined}
          >
            <GatsbyImage
              image={getImage(startUp)}
              alt="start-up.ro"
              className="h-6"
              objectFit="contain"
            />
          </Item>
        </div>
      </div>
    </section>
  );
};

export default SocialProofBar;
