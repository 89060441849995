import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import ProductCard from '@/components/product/ProductCard';
import SocialProofBar from '@/components/product/SocialProofBar';
import ProfessionalContactModal from '@/components/professional/ContactDetailsModal';
import ProfessionalCard from '@/components/professional/ProfessionalCard';
import ProfessionalRequestQuoteModal from '@/components/professional/RequestQuoteModal';
import SupplierCardList from '@/components/supplier/SupplierCardList';
import { useIsMobile } from '@/lib';
import { useModal } from '@/lib/modal';
import {
  ArrowRightIcon,
  ChevronRightIcon,
  MenuIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as React from 'react';
import { FC, useState } from 'react';

const ProductsHomePage: FC<PageProps> = () => {
  const {
    bgImage,
    fgImage,
    showOffProducts,
    showOffSuppliers: { edges: showOffSuppliers },
    showOffProfessionals: { edges: showOffProfessionals },
    categories: { edges: categories },
  } = useStaticQuery(graphql`
    fragment CategoryOnProductsPage on Category {
      name
      qualifiedName
      path
    }

    query {
      bgImage: file(relativePath: { eq: "fso/kitchen-background.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      fgImage: file(
        relativePath: { eq: "fso/transparent-pricing-foreground.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }

      categories: allCategory(
        filter: { qualifiedName: { regex: "/^[^_]*$/" } }
      ) {
        edges {
          node {
            ...CategoryOnProductsPage
            subCategories {
              ...CategoryOnProductsPage
              subCategories {
                ...CategoryOnProductsPage
                subCategories {
                  ...CategoryOnProductsPage
                }
              }
            }
          }
        }
      }

      showOffProducts: randomPresentableProducts(limit: 10) {
        path
        name
        code
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        averageMinPrice {
          value
          currency
        }
        averageMaxPrice {
          value
          currency
        }
      }

      showOffSuppliers: allSupplier(limit: 8) {
        edges {
          node {
            path
            name
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            tags
            areaServed
            category
          }
        }
      }

      showOffProfessionals: allProfessional(limit: 8) {
        edges {
          node {
            path
            name
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            coverImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            tags
            areaServed
            category
            companyName
            description
            address
            website
            phone
            email
            availableFor
            hasBadge
          }
        }
      }
    }
  `);
  const [currentCategory, setCurrentCategory] = useState(null);

  const isMobile = useIsMobile();
  const professionalContactModal = useModal();
  const professionalRequestQuoteModal = useModal();

  return (
    <Layout
      className="ProductsHomePage relative bg-gray-200"
      fsoHeader
      fsoHeaderHideCategoriesMenu={!isMobile}
    >
      <SEO
        url="ro"
        title="Platforma de furnizori cu echipamente HoReCa"
        description=""
        lang="ro"
      />
      <BackgroundImage
        Tag="section"
        className="HeroSection h-2/3screen lg:h-100"
        style={{
          backgroundPosition: isMobile
            ? '72% 80px, center'
            : 'calc(100% + 40px) -50px, center',
          backgroundSize: isMobile ? '700px, cover' : '700px, cover',
        }}
        {...convertToBgImage([getImage(fgImage), getImage(bgImage)])}
      >
        <div
          className="container m-auto w-full h-full grid grid-rows-2"
          style={{
            gridTemplateColumns: isMobile ? 'auto' : 'auto 1fr',
            gridTemplateRows: isMobile ? 'auto 1fr' : '1fr 1fr',
          }}
          onMouseLeave={() => setCurrentCategory(null)}
        >
          <div className="hidden lg:flex col-start-1 row-start-1 row-span-2 container m-auto h-96 p-4">
            <div className="flex flex-col bg-white py-4 shadow">
              <div className="text-primary flex items-center px-4 mb-3">
                <MenuIcon className="h-5 w-5 mr-2" />
                Categorii Produse
              </div>

              {categories
                .map(({ node }) => node)
                .map((category) => (
                  <Link
                    key={category.qualifiedName}
                    className={clsx(
                      'flex justify-between items-center py-1 px-4 text-primary-300 cursor-default whitespace-nowrap',
                      currentCategory?.qualifiedName === category.qualifiedName
                        ? 'font-semibold shadow'
                        : 'opacity-80'
                    )}
                    type="button"
                    onMouseOver={() => setCurrentCategory(category)}
                    to={category.path}
                  >
                    {category.name}
                    <ChevronRightIcon className="ml-2 h-5 w-5 text-primary" />
                  </Link>
                ))}
            </div>
          </div>

          <div className="row-start-1 lg:col-start-2 font-bold text-center lg:text-left text-primary-300 text-2xl lg:text-4xl mt-10 px-7 lg:max-w-lg self-end">
            Produse, Furnizori & Profesionisti HoReCa
          </div>
          <div className="row-start-2 lg:col-start-2 text-center text-base lg:text-left text-primary-300 mt-2 px-7 lg:max-w-lg">
            Cere si compara oferte multiple de la furnizori si profesionisti
            selectati cu grija pentru a gasi cele mai bune preturi si conditii
            pentru afacerea ta.
          </div>

          {currentCategory && (
            <div className="col-start-2 row-span-2 row-start-1 flex flex-1 bg-white shadow-lg m-4 p-4 flex-col flex-wrap max-h-1/2screen text-primary-300">
              {currentCategory.subCategories
                .sort(
                  (s1, s2) => s2.subCategories.length - s1.subCategories.length
                )
                .map((subCategory) => (
                  <div
                    key={subCategory.qualifiedName}
                    className="flex flex-col whitespace-nowrap mr-4"
                  >
                    <Link className="font-semibold" to={subCategory.path}>
                      {subCategory.name}
                    </Link>
                    {subCategory.subCategories.map((subSubCategory) => (
                      <Link
                        key={subSubCategory.qualifiedName}
                        className="opacity-80"
                        to={subSubCategory.path}
                      >
                        {subSubCategory.name}
                      </Link>
                    ))}
                    <div
                      className={clsx(
                        subCategory.subCategories.length ? 'mb-4' : 'mb-1'
                      )}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </BackgroundImage>

      <SocialProofBar className="opacity-50" withLinks />

      <hr className="border-gray-250" />

      <section className="container m-auto p-4">
        <h2 className="mt-10 mb-4 font-semibold text-base">
          Profesionisti adaugati recent
        </h2>
        <div className="grid justify-between gap-10 grid-cols-1">
          {showOffProfessionals.map(({ node: professional }) => (
            <ProfessionalCard
              key={professional.path}
              professional={{
                ...professional,
                image: professional.image?.childImageSharp?.gatsbyImageData,
                coverImage:
                  professional.coverImage?.childImageSharp?.gatsbyImageData,
              }}
              contactModal={professionalContactModal}
              requestQuoteModal={professionalRequestQuoteModal}
              isSearchResult={false}
            />
          ))}
        </div>
        <div className="mt-8 lg:flex justify-end">
          <Link
            to="/ro/cauta-profesionisti/"
            className="w-full lg:w-auto flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-yellow text-black"
          >
            Vezi toti profesionistii
            <ArrowRightIcon className="h-5 w-5 ml-4" />
          </Link>
        </div>
      </section>

      <section className="container m-auto p-4">
        <h2 className="mt-10 mb-4 font-semibold text-base">
          Produse adaugate recent
        </h2>
        <div
          className="grid justify-between gap-2 lg:gap-10 max-w-full"
          style={{
            gridTemplateColumns: isMobile
              ? 'repeat(auto-fill, minmax(9.5rem, 1fr))'
              : 'repeat(auto-fill, 11.5rem)',
          }}
        >
          {showOffProducts
            .map((prod, idx) => ({ prod, idx }))
            .map(({ prod, idx }) => (
              <ProductCard key={idx} prod={prod} />
            ))}
        </div>

        <div className="mt-8 lg:flex justify-end">
          <Link
            to="/ro/cauta-produse/"
            className="w-full lg:w-auto flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-yellow text-black"
          >
            Vezi toate produsele
            <ArrowRightIcon className="h-5 w-5 ml-4" />
          </Link>
        </div>
      </section>

      <section className="container m-auto p-4">
        <h2 className="mt-10 mb-4 font-semibold text-base">
          Furnizori adaugati recent
        </h2>

        <SupplierCardList
          suppliers={showOffSuppliers.map(({ node }) => node)}
        />

        <div className="mt-8 lg:flex justify-end">
          <Link
            to="/ro/cauta-furnizori/"
            className="w-full lg:w-auto flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-yellow text-black"
          >
            Vezi toti furnizorii
            <ArrowRightIcon className="h-5 w-5 ml-4" />
          </Link>
        </div>
      </section>

      <ProfessionalContactModal control={professionalContactModal} />
      <ProfessionalRequestQuoteModal control={professionalRequestQuoteModal} />
    </Layout>
  );
};

export default ProductsHomePage;
